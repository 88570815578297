import {useEffect, useCallback, useRef} from 'react';
import Utils from "../utils/Utils";
import {debounce} from "lodash";

const useResize = (
    winningDraw: number[],
    cellRefs: React.MutableRefObject<{ [key: number]: HTMLElement | null }>,
    gameBoardRef: React.MutableRefObject<HTMLElement | null>
) => {
    const cellPositions = useRef<{ [key: number]: { targetX: number, targetY: number } }>({});

    const getCellPosition = useCallback((number: number) => {
        const cell = cellRefs.current[number];
        if (cell && gameBoardRef.current) {
            const cellRect = cell.getBoundingClientRect();

            const position = {
                targetX: cellRect.left + cellRect.width / 2,
                targetY: cellRect.top + cellRect.height / 2,
            };
            if (cellPositions.current) {
                cellPositions.current[number] = position;
                return position;
            }
        }
        return {targetX: 0, targetY: 0};
    }, []);

    useEffect(() => {
        const handleResize = debounce(() => {
            winningDraw.forEach((number) => {
                getCellPosition(number);
            });
        }, 100);

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return getCellPosition;
};

export default useResize;
