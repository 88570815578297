import React, {useContext, useEffect, useRef, useState} from 'react';
import clsx from "clsx";
import {AnimatePresence, motion} from 'framer-motion';
import {GameContext} from "../../../../contexts/GameContext";
import Button from "../../../elements/Button/Button";
import styles from './GoldNumberButton.module.scss';
import Tooltip from "../../../elements/Tooltip/Tooltip";
import Utils from "../../../../utils/Utils";
import {useTranslation} from "react-i18next";
import {ROUND_END} from "../../../../config/constants";
import {useIsTouchDevice} from "../../../../hooks/useIsTouchDevice";
import useGoldNumberButtonAnimation from "../../../../hooks/useGoldNumberButtonAnimation";

const GoldNumberButton = () => {
    const {t} = useTranslation();
    const buttonRef = useRef<HTMLDivElement | null>(null);
    const isTouchDevice = useIsTouchDevice();
    const getAnimation = useGoldNumberButtonAnimation();
    const {
        isGoldNumberUsed,
        setIsGoldNumberUsed,
        goldNumber,
        setGoldNumber,
        picked,
        setPicked,
        activePrice,
        gameState,
        setIsSelectingGoldNumber,
        isSelectingGoldNumber,
    } = useContext(GameContext);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    const handleAnimationComplete = () => {
        setIsAnimating(false);
    };

    const handleClick = async () => {
        if (gameState === ROUND_END) return;
        if (!isAnimating) {
            const isUsed = !isGoldNumberUsed;
            setIsGoldNumberUsed(isUsed);
            if (isUsed) {
                setIsSelectingGoldNumber(true);
            }
            if (!isUsed) {
                setIsSelectingGoldNumber(false);
                setGoldNumber(0);
                setPicked(picked.filter(number => number !== goldNumber));
            }
        }
    };

    const handleButtonClick = (event: React.MouseEvent | React.TouchEvent) => {
        if (isTouchDevice) {
            event.preventDefault();
            event.stopPropagation();
            setIsTooltipVisible(true);
        }
        handleClick();
    };

    const handleOutsideClick = (event: Event) => {
        if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
            setIsTooltipVisible(false);
        }
    };

    const handleMouseEnter = () => {
        setIsTooltipVisible(true);
    };

    const handleMouseLeave = () => {
        setIsTooltipVisible(false);
    };

    useEffect(() => {
        if (isTouchDevice && isTooltipVisible) {
            document.addEventListener('click', handleOutsideClick);
            document.addEventListener('touchstart', handleOutsideClick, {passive: true});
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
            document.removeEventListener('touchstart', handleOutsideClick);
        };
    }, [isTooltipVisible, isTouchDevice]);

    return (
        <div ref={buttonRef}
             className={styles.goldNumberBox}
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
        >
            <Tooltip isVisible={isTooltipVisible}>
                <h3>{t('actionBoard.goldenNumber.label')}</h3>
            </Tooltip>
            <Button
                className={clsx(styles.goldenNumberButton, {[styles.active]: isGoldNumberUsed})}
                onClick={handleButtonClick}
            >
                <div className={styles.goldenNumberButtonContent}>
                    <label htmlFor="checkbox">
                        {isGoldNumberUsed ? '✓' : ''}
                    </label>
                    <input
                        id="gold-number-checkbox"
                        type='checkbox'
                        className={styles.checkbox}
                        checked={isGoldNumberUsed}
                    />
                    <div className={styles.textBox}>
                        <p className={clsx(styles.multiplierButtonText, {
                            [styles.active]: isGoldNumberUsed
                        })}>{t('actionBoard.goldenNumber.buttonName')}</p>
                        <p className={clsx(styles.multiplierButtonPrice, {
                            [styles.active]: isGoldNumberUsed
                        })}>{'+ ' + Utils.formatCurrency(activePrice)}</p>
                    </div>
                </div>
            </Button>
            <AnimatePresence>
                <motion.div
                    key="number"
                    className={styles.goldNumber}
                    initial={getAnimation.variants.inactive}
                    animate={isGoldNumberUsed ? "active" : "inactive"}
                    whileHover={{
                        scale: 1.05,
                        rotate: 0,
                        transition: {duration: 0.2}
                    }}
                    variants={{
                        active: getAnimation.variants.active,
                        inactive: getAnimation.variants.inactive
                    }}
                    onAnimationStart={() => setIsAnimating(true)}
                    onAnimationComplete={handleAnimationComplete}
                >
                    <span> {goldNumber > 0 ? goldNumber : '?'}</span>
                </motion.div>
            </AnimatePresence>
        </div>
    );
};

export default GoldNumberButton;
