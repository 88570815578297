import React, {useContext, useEffect, useRef, useState} from "react";
import clsx from "clsx";
import {motion} from 'framer-motion';
import styles from "./Board.module.scss";
import Randomizer from "./components/Randomizer/Randomizer";
import {GameContext} from "../../contexts/GameContext";
import {GAME, ROUND_END, START} from "../../config/constants";
import Ball from "./components/Ball/Ball";
import Cells from "./components/Cells/Cells";
import EndGameActionBoard from "../EndGameBoard/EndGameBoard";
import SkipDrawing from "../SkipDrawing/SkipDrawing";
import useBallsResize from "../../hooks/useBallsResize";
import ActionBoard from "../ActionBoard/ActionBoard";
import Utils from "../../utils/Utils";

interface BoardProps {
    handlePick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, val: number) => void;
}

const Board: React.FC<BoardProps> = ({handlePick}) => {
    const {
        winningDraw,
        gameState,
        setIsResultsShown,
        isResultsShown,
        setGameState,
        setCanChangeTotalRowWidth,
        showSkipDrawing,
        setBouncedBallNumbers,
    } = useContext(GameContext);
    const cellRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
    const gameBoardRef = useRef<HTMLDivElement | null>(null);
    const unbouncedBallsRef = useRef<HTMLDivElement[]>([]);
    const getCellPosition = useBallsResize(winningDraw, cellRefs, gameBoardRef);

    const [bouncedBalls, setBouncedBalls] = useState(0);
    const ballCount = winningDraw && winningDraw.length > 0 ? winningDraw.length : 20;

    const handleBallBounceComplete = (number: number, index: number) => {
        setBouncedBallNumbers((prev) => [...prev, number]);
        setBouncedBalls(index + 1);
    };

    const resetBouncedBalls = () => {
        setCanChangeTotalRowWidth(true);
        setBouncedBallNumbers([]);
        setBouncedBalls(0);
    };

    useEffect(() => {
        if (bouncedBalls === ballCount) {
            setIsResultsShown(true);
        }
    }, [bouncedBalls, ballCount]);

    useEffect(() => {
        if (isResultsShown) {
            setTimeout(() => {
                resetBouncedBalls();
                setGameState(ROUND_END);
            }, 4000);
        }
    }, [isResultsShown]);

    useEffect(() => {
        const handleResize = () => {
            unbouncedBallsRef.current = [];
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    return (
        <motion.div
            id={'board'}
            ref={gameBoardRef}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.5}}
            className={clsx(styles.board, {
                [styles[gameState]]: gameState,
            })}
        >
            {gameState !== GAME && gameState !== ROUND_END && Utils.getScreenWidth() < 1200 && (
                <Randomizer/>
            )}
            {(gameState === GAME || gameState === START) && (
                <Cells cellRefs={cellRefs} handlePick={handlePick}/>
            )}
            {gameState === ROUND_END && (
                <EndGameActionBoard/>
            )}
            {gameState === START && (
                <ActionBoard/>
            )}
            {gameState === GAME && showSkipDrawing && (
                <SkipDrawing/>
            )}

            {winningDraw.map((number, index) => {
                const {targetX, targetY} = getCellPosition(number);
                return (
                    <Ball
                        key={number}
                        getCellPosition={getCellPosition}
                        targetX={targetX}
                        targetY={targetY}
                        unbouncedBallsRef={unbouncedBallsRef}
                        number={number}
                        delay={index * 1.4}
                        onBounceComplete={() => handleBallBounceComplete(number, index)}
                    />
                );
            })}
        </motion.div>
    );
};

export default Board;
