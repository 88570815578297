//  Game states
export const MULTIPLIER: string = 'multiplier';
export const GAME: string = 'game';
export const START: string = 'start';
export const ROUND_END: string = 'endGame';
export const INFO: string = 'info';
export const STATS: string = 'stats';

//  Modals
export const LOGIN: string = 'LOGIN';
export const WALLET: string = 'WALLET';
export const SESSION_TIMEOUT: string = 'SESSION_TIMEOUT';
export const UNFINISHED: string = 'UNFINISHED';
export const ERROR: string = 'ERROR';
