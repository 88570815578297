import React, {useContext, useEffect, useState} from 'react';
import Utils from "../../../utils/Utils";
import styles from './PrizesTable.module.scss';
import {GameContext} from "../../../contexts/GameContext";

interface TableHeader {
    tableHeader: string;
    imgSrc?: string;
}

interface Prizes {
    correctNumbers: number;
    prizeFactor: number;
    specialPrizeFactor: number;
}

interface PrizePlanState {
    chosenNumbersCount: number;
    isSpecialPrize: boolean;
    prizes: Prizes[];
}

interface PrizePlansProps {
    tableHeaders: TableHeader[];
}

const PrizesTable = ({tableHeaders}: PrizePlansProps) => {
    const {prizePlans, picked, isGoldNumberUsed, activePrice} = useContext(GameContext);
    const [selectedPrizePlan, setSelectedPrizePlan] = useState<PrizePlanState>();

    useEffect(() => {
        let plan = prizePlans.find(plan =>
            plan.chosenNumbersCount === picked.length &&
            !plan.isSpecialPrize,
        );

        if (!plan) {
            plan = prizePlans.reduce((maxPlan, currentPlan) =>
                currentPlan.chosenNumbersCount > (maxPlan?.chosenNumbersCount || 0) ? currentPlan : maxPlan, null);
        }

        setSelectedPrizePlan(plan || null);
    }, [prizePlans, picked.length, isGoldNumberUsed]);

    return (
        <div className={styles.tableBox}>
            <table className={styles.table}>
                <thead>
                <tr className={styles.row}>
                    {tableHeaders &&
                        tableHeaders.map(({tableHeader, imgSrc}) => {
                            return (
                                <th key={tableHeader} className={styles.tableHeader}>
                                    {tableHeader}
                                    {imgSrc && <img src={imgSrc} alt={tableHeader} className={styles.headerImage} />}
                                </th>
                            );
                        })}
                </tr>
                </thead>
                <tbody>
                {selectedPrizePlan && selectedPrizePlan.prizes.slice().reverse().map(({correctNumbers, prizeFactor, specialPrizeFactor}) => (
                    <tr className={styles.row} key={correctNumbers}>
                        <td className={styles.tableData}>
                            {correctNumbers}
                        </td>
                        <td className={styles.tableData}>
                            {prizeFactor === 0 ? '-' : Utils.formatCurrency(prizeFactor * activePrice)}
                        </td>
                        <td className={styles.tableData}>
                            {specialPrizeFactor === 0 ? '-' : Utils.formatCurrency(specialPrizeFactor * activePrice)}
                        </td>
                    </tr>
                ))}
                {selectedPrizePlan === null && (
                    <tr className={styles.row}>
                        <td colSpan={3} className={styles.noData}>
                            {'No prizes available. Select at least 1 number.'}
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
};

export default PrizesTable;
