import {useEffect, useState} from 'react';

const useGoldNumberButtonAnimation = () => {
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const mobileAnimation = {
        variants: {
            active: {
                scale: 1.1,
                rotate: 0,
                transition: {
                    duration: 0.3
                }
            },
            inactive: {
                opacity: 1,
                scale: 1,
                x: 37,
                y: -10,
                rotate: 10,
                transition: {
                    duration: 0.3,
                }
            },
        },
    };

    const desktopAnimation = {
        variants: {
            active: {
                scale: 1.1,
                rotate: 0,
                transition: {
                    duration: 0.3
                }
            },
            inactive: {
                opacity: 1,
                scale: 1,
                x: 47,
                y: -7,
                rotate: 10,
                transition: {
                    duration: 0.3,
                }
            },
        },
    };

    const tabletAnimation = {...desktopAnimation};

    const getAnimation = () => {
        if (windowWidth <= 767) {
            return mobileAnimation;
        } else if (windowWidth <= 1200) {
            return tabletAnimation;
        } else {
            return desktopAnimation;
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return getAnimation();
}

export default useGoldNumberButtonAnimation;
