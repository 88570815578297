import React, {createContext, useState, ReactNode, useEffect, useContext} from 'react';
// @ts-ignore
import Chance from "chance";
import {ERROR, START, WALLET} from "../config/constants";
import {useMutation, useQuery} from "react-query";
import apiClient from "../utils/apiClient";
import {AppContext} from "./AppContext";

const chance = new Chance();

interface TicketData {
    correlationId: string;
    url: string;
    verb: string;
}

interface DemoTicketData {
    drawnNumbers: number[];
    multiplier: number;
    prizeValue: number;
}

interface PrizeFactors {
    multiplierPriceFactor: number;
    goldNumberPriceFactor: number;
}

interface GameProviderProps {
    children: ReactNode;
    value: Partial<GameContextProps>;
}

interface GameContextProps {
    board: number[][];
    setBoard: React.Dispatch<React.SetStateAction<number[][]>>;
    currentTicket: any;
    setCurrentTicket: React.Dispatch<React.SetStateAction<any>>;
    unfinishedTicket: any | null;
    setUnfinishedTicket: React.Dispatch<React.SetStateAction<any | null>>;
    picked: number[];
    setPicked: React.Dispatch<React.SetStateAction<number[]>>;
    pickLimit: number;
    winningDraw: number[];
    setWinningDraw: React.Dispatch<React.SetStateAction<number[]>>;
    showWinningDraw: () => void;
    disabled: boolean;
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    prices: number[];
    setPrices: React.Dispatch<React.SetStateAction<number[]>>;
    activePrice: number;
    setActivePrice: React.Dispatch<React.SetStateAction<number>>;
    gamePrice: number;
    setGamePrice: React.Dispatch<React.SetStateAction<number>>;
    checkGamePrice: () => void;
    multiplier: number | null;
    setMultiplier: React.Dispatch<React.SetStateAction<number | null>>;
    isMultiplierUsed: boolean;
    setIsMultiplierUsed: React.Dispatch<React.SetStateAction<boolean>>;
    goldNumber: number;
    setGoldNumber: React.Dispatch<React.SetStateAction<number>>;
    isGoldNumberUsed: boolean;
    setIsGoldNumberUsed: React.Dispatch<React.SetStateAction<boolean>>;
    gameState: string;
    setGameState: React.Dispatch<React.SetStateAction<string>>;
    isResultsShown: boolean;
    setIsResultsShown: React.Dispatch<React.SetStateAction<boolean>>;
    getTicket: any;
    buyTicket: () => void;
    buyDemoGame: () => void;
    prizePlans: any[];
    setPrizePlans: React.Dispatch<React.SetStateAction<any[]>>;
    speedMultiplier: number;
    setSpeedMultiplier: React.Dispatch<React.SetStateAction<number>>;
    showSkipDrawing: boolean;
    setShowSkipDrawing: React.Dispatch<React.SetStateAction<boolean>>;
    canChangeTotalRowWidth: boolean;
    setCanChangeTotalRowWidth: React.Dispatch<React.SetStateAction<boolean>>;
    multipliersArray: number[];
    setMultipliersArray: React.Dispatch<React.SetStateAction<number[]>>;
    isDemo: boolean;
    setIsDemo: React.Dispatch<React.SetStateAction<boolean>>;
    demoBalance: number | null;
    setDemoBalance: React.Dispatch<React.SetStateAction<number | null>>;
    demoTicket: DemoTicketData;
    setDemoTicket: React.Dispatch<React.SetStateAction<DemoTicketData>>;
    bouncedBallNumbers: number[];
    setBouncedBallNumbers: React.Dispatch<React.SetStateAction<number[]>>;
    isSelectingGoldNumber: boolean;
    setIsSelectingGoldNumber: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialGameContextProps: GameContextProps = {
    board: [],
    setBoard: () => {
    },
    currentTicket: {},
    setCurrentTicket: () => {
    },
    unfinishedTicket: null,
    setUnfinishedTicket: () => {
    },
    picked: [],
    setPicked: () => {
    },
    pickLimit: 11,
    winningDraw: [],
    setWinningDraw: () => {
    },
    showWinningDraw: () => {
    },
    disabled: false,
    setDisabled: () => {
    },
    prices: [1, 2, 3],
    setPrices: () => {
    },
    activePrice: 1,
    setActivePrice: () => {
    },
    gamePrice: 0,
    setGamePrice: () => {
    },
    checkGamePrice: () => {
    },
    multiplier: null,
    setMultiplier: () => {
    },
    isMultiplierUsed: false,
    setIsMultiplierUsed: () => {
    },
    goldNumber: 0,
    setGoldNumber: () => {
    },
    isGoldNumberUsed: false,
    setIsGoldNumberUsed: () => {
    },
    gameState: START,
    setGameState: () => {
    },
    isResultsShown: false,
    setIsResultsShown: () => {
    },
    getTicket: () => {
    },
    buyTicket: () => {
    },
    buyDemoGame: () => {
    },
    prizePlans: [],
    setPrizePlans: () => {
    },
    speedMultiplier: 1,
    setSpeedMultiplier: () => {
    },
    showSkipDrawing: false,
    setShowSkipDrawing: () => {
    },
    canChangeTotalRowWidth: true,
    setCanChangeTotalRowWidth: () => {
    },
    multipliersArray: [],
    setMultipliersArray: () => {
    },
    isDemo: false,
    setIsDemo: () => {
    },
    demoBalance: null,
    setDemoBalance: () => {
    },
    demoTicket: {
        drawnNumbers: [],
        multiplier: 1,
        prizeValue: 0,
    },
    setDemoTicket: () => {
    },
    bouncedBallNumbers: [],
    setBouncedBallNumbers: () => {
    },
    isSelectingGoldNumber: false,
    setIsSelectingGoldNumber: () => {
    },
};


const GameContext = createContext<GameContextProps>(initialGameContextProps);

function GameProvider({children, value}: GameProviderProps) {
    const {tokenData, callback, setUserPaymentSuccess, refetchCustomerBalance, setCurrentModal} = useContext(AppContext);

    const [board, setBoard] = useState<number[][]>([]);
    const [currentTicket, setCurrentTicket] = useState<any>({});
    const [unfinishedTicket, setUnfinishedTicket] = useState<any | null>(null);
    const [picked, setPicked] = useState<number[]>([]);
    const [pickLimit] = useState<number>(11);
    const [winningDraw, setWinningDraw] = useState<number[]>([]);
    const [disabled, setDisabled] = useState(false);
    const [prices, setPrices] = useState<number[]>([1, 2, 3]);
    const [activePrice, setActivePrice] = useState<number>(prices[0]);
    const [gamePrice, setGamePrice] = useState<number>(0);
    const [multiplier, setMultiplier] = useState<number | null>(null);
    const [isMultiplierUsed, setIsMultiplierUsed] = useState(false);
    const [goldNumber, setGoldNumber] = useState<number>(0);
    const [isGoldNumberUsed, setIsGoldNumberUsed] = useState(false);
    const [gameState, setGameState] = useState<string>(START);
    const [isResultsShown, setIsResultsShown] = useState<boolean>(false);
    const [prizePlans, setPrizePlans] = useState<any[]>([]);
    const [speedMultiplier, setSpeedMultiplier] = useState<number>(1);
    const [showSkipDrawing, setShowSkipDrawing] = useState<boolean>(false);
    const [canChangeTotalRowWidth, setCanChangeTotalRowWidth] = useState(true);
    const [prizeFactors, setPrizeFactors] = useState<PrizeFactors>({multiplierPriceFactor: 1, goldNumberPriceFactor: 1});
    const [multipliersArray, setMultipliersArray] = useState<number[]>([]);
    const [isDemo, setIsDemo] = useState<boolean>(false);
    const [demoBalance, setDemoBalance] = useState<number | null>(null);
    const [demoTicket, setDemoTicket] = useState<DemoTicketData>({ multiplier: 1, prizeValue: 0, drawnNumbers: [] });
    const [bouncedBallNumbers, setBouncedBallNumbers] = useState<number[]>([]);
    const [isSelectingGoldNumber, setIsSelectingGoldNumber] = useState(false);

    useQuery(
        'prizePlans',
        () =>
            apiClient.get(`api/Keno/Game/219`, {}),
        {
            enabled: prizePlans.length === 0,
            onSuccess: (response: any) => {
                setPrizePlans(response[0].prizePlans);
                setPrices(response[0].wagers);
                setPrizeFactors({
                    multiplierPriceFactor: response[0].multiplierPriceFactor,
                    goldNumberPriceFactor: response[0].specialNumberPriceFactor,
                });
                setMultipliersArray(response[0].multipliers);
            },
            onError: () => {
                setCurrentModal(ERROR);
            }
        }
    );

    const purchase = useMutation(
        (putData: {}) => apiClient.put('api/Game', putData),
        {
            retry: false,
            onSuccess: (response: any) => {
                setUserPaymentSuccess(true);
                activateTicket(response[0])
                if (tokenData) {
                    refetchCustomerBalance();
                }
            },
            onError: (error: any) => {
                console.log('error: ', error.data);
                if (error.data === 'Not_Enough_Funds_To_Pay') {
                    setCurrentModal(WALLET);
                } else {
                    setCurrentModal(ERROR);
                }
            },
        }
    );

    const purchaseDemo = useMutation(
        (putData: {}) => apiClient.put('api/Keno/Tickets/demoticket/76', putData),
        {
            retry: false,
            onSuccess: (response: any) => {
                setUserPaymentSuccess(true);
            },
            onError: (error: any) => {
                setCurrentModal(ERROR);
            },
        }
    );

    const buyDemoGame = async () => {
        const payload =
            [{
                gameType: 'Keno',
                pluginGameId: 76,
                wager: gamePrice,
                chosenNumbers: picked,
                SpecialNumber: goldNumber > 0 ? goldNumber : null,
                BuyMultiplier: isMultiplierUsed,
            }];

        try {
            const result = await purchaseDemo.mutateAsync(payload);
            // @ts-ignore
            setDemoTicket(result)
            return result;
        } catch (error: any) {
            console.error('Mutation error:', error.response?.data || error);
            setCurrentModal(ERROR);
            return null;
        }
    };


    const getTicket = useMutation((url: string) =>
        apiClient.get(`${url}`, {})
    );

    const buyTicket = async () => {
        if (!tokenData) return null;
        try {
            const response = await purchase.mutateAsync({
                gameId: 219,
                channel: '',
                additionalData: '',
                buyArguments: [{
                    gameType: 'Keno',
                    pluginGameId: 76,
                    wager: gamePrice,
                    chosenNumbers: picked,
                    SpecialNumber: goldNumber > 0 ? goldNumber : null,
                    BuyMultiplier: isMultiplierUsed,
                }],
            });

            if (response) {
                if (callback) {
                    callback('wager', {
                        wager: gamePrice,
                        amount: 1,
                        gameCollectionId: 219,
                    });
                }
                return response;
            }
        } catch (error: any) {
            console.warn('error: ', error.data);
            if (error.data === 'Not_Enough_Funds_To_Pay') {
                setCurrentModal(WALLET);
            } else {
                setCurrentModal(ERROR);
            }
            return null;
        }
    };

    const activateTicket = async (ticketData: TicketData) => {
        try {
            const res = await getTicket.mutateAsync(ticketData.url);
            if (res) {
                setCurrentTicket(res);
            }
        } catch (err: any) {
            console.error('Error in activating ticket: ', err);
            setCurrentModal(ERROR);
        }
    };

    const showWinningDraw = () => {
        let draw;
        if (!currentTicket.drawnNumbers && !isDemo) {
            // draw = [1,2,3,4,5,6,7,8,9]
            draw = chance.unique(chance.natural, 20, {min: 1, max: 80});
        } else if (isDemo) {
            draw = demoTicket.drawnNumbers;
        } else {
            draw = currentTicket.drawnNumbers;
        }
        setWinningDraw(draw);
    };

    const checkGamePrice = () => {
        let multiplier = 1;

        if (isMultiplierUsed) {
            multiplier *= prizeFactors.multiplierPriceFactor;
        }
        if (isGoldNumberUsed) {
            multiplier *= prizeFactors.goldNumberPriceFactor;
        }

        const totalPrice = activePrice * multiplier;
        setGamePrice(totalPrice);
    };

    useEffect(() => {
        checkGamePrice();
    }, [activePrice, isMultiplierUsed, isGoldNumberUsed]);

    return (
        <GameContext.Provider
            value={{
                ...value,
                board,
                setBoard,
                currentTicket,
                setCurrentTicket,
                unfinishedTicket,
                setUnfinishedTicket,
                picked,
                setPicked,
                pickLimit,
                winningDraw,
                setWinningDraw,
                showWinningDraw,
                disabled,
                setDisabled,
                prices,
                setPrices,
                activePrice,
                setActivePrice,
                gamePrice,
                setGamePrice,
                checkGamePrice,
                multiplier,
                setMultiplier,
                isMultiplierUsed,
                setIsMultiplierUsed,
                goldNumber,
                setGoldNumber,
                isGoldNumberUsed,
                setIsGoldNumberUsed,
                gameState,
                setGameState,
                isResultsShown,
                setIsResultsShown,
                getTicket,
                buyTicket,
                buyDemoGame,
                prizePlans,
                setPrizePlans,
                speedMultiplier,
                setSpeedMultiplier,
                showSkipDrawing,
                setShowSkipDrawing,
                canChangeTotalRowWidth,
                setCanChangeTotalRowWidth,
                multipliersArray,
                setMultipliersArray,
                isDemo,
                setIsDemo,
                demoBalance,
                setDemoBalance,
                demoTicket,
                setDemoTicket,
                bouncedBallNumbers,
                setBouncedBallNumbers,
                isSelectingGoldNumber,
                setIsSelectingGoldNumber,
            }}>
            {children}
        </GameContext.Provider>
    );
}

GameProvider.defaultProps = {
    value: {
        picked: [],
        pickLimit: 11,
        winningDraw: [],
        disabled: false,
        prices: [1000, 2000, 3000],
        multiplier: 1,
        isMultiplierUsed: false,
        goldNumber: 0,
        isGoldNumberUsed: false,
        gameState: START,
        isResultsShown: false,
    },
};

export {GameContext, GameProvider};
