import {MutableRefObject, useContext, useEffect, useRef, useState} from 'react';
import clsx from "clsx";
import {gsap} from 'gsap';
import {AppContext} from "../../../../contexts/AppContext";
import {ReactComponent as Coin} from '../../../../assets/svg/coin.svg';
import Utils from "../../../../utils/Utils";
import styles from './Wallet.module.scss';
import {GameContext} from "../../../../contexts/GameContext";

interface Props {
    displayBalance: boolean;
}

function Wallet({displayBalance}: Props): JSX.Element {
    const {userBalance, tokenData} = useContext(AppContext);
    const {demoBalance, isDemo} = useContext(GameContext);

    const [total, setTotal] = useState<string>('0');
    const [totalDemoBalance, setTotalDemoBalance] = useState<string>('');

    const previousBalance: MutableRefObject<any> = useRef();
    const balance: MutableRefObject<any> = useRef();
    const demoBalanceRef = useRef<any>();

    const bounceBalance: () => void = () => {
        gsap.to(balance.current, {
            scale: 1.1,
            x: 5,
            ease: 'Sine.easeOut',
            duration: 0.4,
        });
        gsap.to(balance.current, {
            scale: 1,
            x: 0,
            delay: 0.1,
            ease: 'Sine.easeOut',
        });
    };

    const animateBalance: () => void = () => {
        gsap.to(previousBalance.current, {
            value: userBalance?.totalValue,
            duration: 0.5,
            ease: 'power2.out',
            onUpdate: () => {
                const totalString: string = Utils.formatCurrency(previousBalance.current.value);
                setTotal(totalString);

                bounceBalance();
            },
        });
    };

    const animateDemoBalance: () => void = () => {
        gsap.to(demoBalanceRef.current, {
            value: demoBalance,
            duration: 0.5,
            ease: 'power2.out',
            onUpdate: () => {
                const totalString: string = Utils.formatCurrency(demoBalanceRef.current.value);
                setTotalDemoBalance(totalString);
            },
        });
    };

    useEffect(() => {
        if (userBalance?.totalValue !== null) {
            setTotal((oldVal: string) => {
                if (oldVal) {
                    const oldValNumber: number = Number(oldVal.replace(/[^0-9.]+/g, ''));

                    previousBalance.current = {
                        value: oldValNumber,
                    };
                    if (userBalance !== null) {
                        if (oldValNumber < userBalance.totalValue) {
                            animateBalance();
                        }
                    }
                }

                if (userBalance !== null) {
                    return Utils.formatCurrency(userBalance.totalValue);
                }
                return '';
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userBalance]);

    useEffect(() => {
        if (demoBalance !== null) {
            const currentFunModeBalance: number = demoBalance;
            setTotalDemoBalance((oldVal: string) => {
                if (oldVal) {
                    const oldValNumber: number = Number(oldVal.replace(/[^0-9.]+/g, ''));
                    demoBalanceRef.current = {
                        value: oldValNumber,
                    };

                    if (oldValNumber !== currentFunModeBalance) {
                        animateDemoBalance();
                    }
                }

                if (demoBalance !== null) {
                    return Utils.formatCurrency(demoBalance);
                }
                return '';
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [demoBalance]);

    return (
        <div className={styles.balanceWrapper}>
            {isDemo && !tokenData?.access_token ? (
                demoBalance !== null && (
                    <div className={clsx(styles.box, {[styles.hideBalance]: !displayBalance})}>
                        <Coin className={styles.coinIcon}/>
                        <span ref={demoBalanceRef} className={styles.balance}>
                        {totalDemoBalance}
                    </span>
                    </div>
                )
            ) : (
                userBalance !== null && (
                    <div className={clsx(styles.box, {[styles.hideBalance]: !displayBalance})}>
                        <Coin className={styles.coinIcon}/>
                        <span ref={balance} className={styles.balance}>
                        {total}
                    </span>
                    </div>
                )
            )}
        </div>
    );
}

export default Wallet;
