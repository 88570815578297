import React from 'react';
import Button from "../elements/Button/Button";
import styles from './SkipDrawing.module.scss';
import {useTranslation} from "react-i18next";
import normalSpeedIcon from "../../assets/svg/normal-speed.svg";
import skipDrawingIcon from "../../assets/svg/skip-drawing.svg";
import clsx from "clsx";
import Switch from "../elements/Switch/Switch";

const SkipDrawing = () => {
    const {t} = useTranslation();

    return (
        <div className={styles.skipDrawingContainer}>
            <p className={styles.skipDrawing}>
                {t('actionBoard.skipDrawing')}
            </p>

            <div className={styles.toggleContainer}>
                <img src={normalSpeedIcon} className={clsx(styles.skipIcon)} alt=""/>
                <Switch/>
                <img src={skipDrawingIcon} className={clsx(styles.skipIcon)} alt=""/>
            </div>
        </div>
    );
};

export default SkipDrawing;
