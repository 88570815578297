import React, {useContext, useEffect, useRef, useState} from 'react';
import clsx from "clsx";
import {AnimatePresence, motion} from 'framer-motion';
import {GameContext} from "../../../../contexts/GameContext";
import Button from "../../../elements/Button/Button";
import styles from './MultiplierButton.module.scss';
import ButtonWheel from '../../../../assets/wheel/button-wheel.png';
import Tooltip from "../../../elements/Tooltip/Tooltip";
import Utils from "../../../../utils/Utils";
import {useTranslation} from "react-i18next";
import {ROUND_END} from "../../../../config/constants";
import {useIsTouchDevice} from "../../../../hooks/useIsTouchDevice";
import useMultiplierButtonAnimation from "../../../../hooks/useMultiplierButtonAnimation";

const MultiplierButton = () => {
    const {t} = useTranslation();
    const buttonRef = useRef<HTMLDivElement | null>(null);
    const isTouchDevice = useIsTouchDevice();
    const getAnimation = useMultiplierButtonAnimation();
    const {isMultiplierUsed, setIsMultiplierUsed, activePrice, gameState} = useContext(GameContext);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    const handleAnimationComplete = () => {
        setIsAnimating(false);
    };

    const handleClick = async () => {
        if (gameState === ROUND_END) return;
        if (isAnimating) return;
        setIsMultiplierUsed(!isMultiplierUsed);
    }

    const handleButtonClick = (event: React.MouseEvent | React.TouchEvent) => {
        if (isTouchDevice) {
            event.preventDefault();
            event.stopPropagation();
            setIsTooltipVisible(true);
        }
        handleClick();
    };

    const handleOutsideClick = (event: Event) => {
        if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
            setIsTooltipVisible(false);
        }
    };

    const handleMouseEnter = () => {
        if (!isTouchDevice) {
            setIsTooltipVisible(true);
        }
    };

    const handleMouseLeave = () => {
        if (!isTouchDevice) {
            setIsTooltipVisible(false);
        }
    };

    useEffect(() => {
        if (isTouchDevice && isTooltipVisible) {
            document.addEventListener('click', handleOutsideClick);
            document.addEventListener('touchstart', handleOutsideClick, { passive: true });
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
            document.removeEventListener('touchstart', handleOutsideClick);
        };
    }, [isTooltipVisible, isTouchDevice]);

    return (
        <div className={styles.multiplierBox}
             ref={buttonRef}
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
        >
            <Tooltip isVisible={isTooltipVisible}>
                <h3>{t('actionBoard.multiplier.label')}</h3>
                <p>{t('actionBoard.multiplier.subHeading')}</p>
            </Tooltip>
            <Button
                className={clsx(styles.multiplierButton, {[styles.inactive]: !isMultiplierUsed})}
                onClick={handleButtonClick}
            >
                <div className={styles.multiplierButtonContent}>
                    <label htmlFor="checkbox">
                        {isMultiplierUsed ? '✓' : ''}
                    </label>
                    <input
                        id="multiplier-checkbox"
                        type='checkbox'
                        className={styles.checkbox}
                        checked={isMultiplierUsed}
                    />
                    <div className={styles.textBox}>
                        <p className={styles.multiplierButtonText}>Multiplier</p>
                        <p className={clsx(styles.multiplierButtonPrice, {
                            [styles.active]: isMultiplierUsed
                        })}>{'+ ' + Utils.formatCurrency(activePrice)}</p>
                    </div>
                </div>
            </Button>
            <AnimatePresence>
                <motion.img
                    key="wheel"
                    alt="wheel"
                    className={styles.wheel}
                    src={ButtonWheel}
                    initial={getAnimation.variants.inactive}
                    variants={{
                        active: getAnimation.variants.active,
                        inactive: getAnimation.variants.inactive
                    }}
                    animate={isMultiplierUsed ? "active" : "inactive"}
                    whileHover={{
                        scale: 1.05,
                        rotate: 90,
                        transition: {duration: 0.2}
                    }}
                    onAnimationStart={() => setIsAnimating(true)}
                    onAnimationComplete={handleAnimationComplete}
                >
                </motion.img>
            </AnimatePresence>
        </div>
    );
};

export default MultiplierButton;
