import text from 'i18next';

export default class Utils {
    static formatSpaceBetweenDigits(number: number) {
        if (number === null || number === undefined) {
            return '0';
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    static formatMultiplier(multiplier: number) {
        if (multiplier === null || multiplier === undefined) {
            return '0x';
        }
        return `${multiplier}x`;
    }

    static formatCurrency(amount: number) {
        const currencySettings = {
            style: 'currency',
            currency: text.t('currencyFormat') || 'EUR',
            minimumFractionDigits: 0,
            currencyDisplay: 'symbol',
        };

        const formattedAmount = new Intl.NumberFormat(
            text.t('currencyLocale') || 'en-GB',
            currencySettings
        ).format(amount);

        return formattedAmount.replace(/(\D*)(\d.*)(\D*)/, '$2 $1');
    }

    static isSmallMobile() {
        return window.innerWidth <= 375;
    }

    static isMobile() {
        return window.innerWidth <= 768;
    }

    static isTablet() {
        return window.innerWidth > 768 && window.innerWidth <= 1200;
    }

    static isDesktop() {
        return window.innerWidth > 1200;
    }

    static isDesktopLarge() {
        return window.innerWidth > 1440 && window.innerHeight > 1000;
    }

    static getScreenWidth() {
        let width = window.innerWidth;
        window.addEventListener('resize', () => {
            width = window.innerWidth;
        });
        return width;
    }

    static timeout(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
