import React, {useContext, useEffect, useRef, useState} from 'react';
import {motion} from 'framer-motion';
import PrizesTable from "./components/PrizesTable";
import arrowIcon from '../../assets/svg/prizes-arrow.svg'
import goldNumber from '../../assets/prizes/gold-number.png'
import styles from './PrizePlans.module.scss';
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {GameContext} from "../../contexts/GameContext";
import Utils from "../../utils/Utils";
import {useIsTouchDevice} from "../../hooks/useIsTouchDevice";

const PrizePlans = () => {
    const {t} = useTranslation()
    const {activePrice} = useContext(GameContext)
    const [isOpen, setIsOpen] = useState(false);
    const isTouchDevice = useIsTouchDevice();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const toggleContainer = () => setIsOpen(!isOpen);

    const handleOutsideClick = (event: Event) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    const containerVariants = {
        hidden: {x: '-307px'},
        visible: {
            x: 0,
            transition: {
                type: 'spring',
                stiffness: 280,
                damping: 30,
            },
        },
    };

    useEffect(() => {
        if (isTouchDevice && isOpen) {
            document.addEventListener('click', handleOutsideClick);
            document.addEventListener('touchstart', handleOutsideClick, { passive: true });
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
            document.removeEventListener('touchstart', handleOutsideClick);
        };
    }, [isOpen, isTouchDevice]);

    return (
        <>
            <motion.div
                key={'bookmark'}
                ref={containerRef}
                className={clsx(styles.bookmark, {
                    [styles.bookmarkOpen]: isOpen,
                })}
                onClick={toggleContainer}
                initial={{x: -110}}
                animate={{
                    x: isOpen ? 170 : -100,
                    backgroundColor: isOpen ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.4)'
                }}
                transition={{type: 'spring', stiffness: 280, damping: 30}}
            >
                <span className={styles.bookmarkText}>{t('prizes.header')}</span>
                <motion.img
                    key={'bookmarkIcon'}
                    src={arrowIcon}
                    className={styles.bookmarkIcon}
                    initial={{rotate: 0}}
                    animate={{rotate: isOpen ? 180 : 0}}
                    transition={{duration: 0.3}}
                    alt={'arrow'}
                >
                </motion.img>
            </motion.div>

            <motion.div
                className={styles.container}
                initial="hidden"
                animate={isOpen ? 'visible' : 'hidden'}
                variants={containerVariants}
            >
                <div className={styles.headerBox}>
                    <h1 className={styles.header}>
                        {t('prizes.header')}
                    </h1>
                    <h3 className={styles.stake}>
                        {t('prizes.stake', {stake: Utils.formatCurrency(activePrice)})}
                    </h3>
                </div>
                <hr className={styles.breakLine}/>
                <div className={styles.containerContent}>
                    <PrizesTable
                        tableHeaders={[
                            {tableHeader: t('prizes.hits')},
                            {tableHeader: t('prizes.kenoPrize')},
                            {
                                tableHeader: t('prizes.specialPrize'),
                                imgSrc: goldNumber,
                            },
                        ]}
                    />
                </div>
            </motion.div>
        </>
    );
};

export default PrizePlans;
