import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {motion} from "framer-motion";
import styles from "./GameInfoTab.module.scss";
import arrowIcon from "../../assets/svg/prizes-arrow.svg";
import clsx from "clsx";
import Randomizer from "../Board/components/Randomizer/Randomizer";
import {useIsTouchDevice} from "../../hooks/useIsTouchDevice";

const GameInfoTab = () => {
    const {t} = useTranslation()
    const [isOpen, setIsOpen] = useState(false);
    const isTouchDevice = useIsTouchDevice();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const toggleContainer = () => setIsOpen(!isOpen);

    const handleOutsideClick = (event: Event) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    const containerVariants = {
        hidden: { x: '307px' },
        visible: {
            x: 0,
            transition: {
                type: 'spring',
                stiffness: 280,
                damping: 30,
            },
        },
    };

    useEffect(() => {
        if (isTouchDevice && isOpen) {
            document.addEventListener('click', handleOutsideClick);
            document.addEventListener('touchstart', handleOutsideClick, { passive: true });
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
            document.removeEventListener('touchstart', handleOutsideClick);
        };
    }, [isOpen, isTouchDevice]);

    return (
        <>
            <motion.div
                key={'bookmark'}
                ref={containerRef}
                className={clsx(styles.bookmark, {
                    [styles.bookmarkOpen]: isOpen,
                })}
                onClick={toggleContainer}
                initial={{x: 100}}
                animate={{
                    x: isOpen ? -170 : 100,
                    backgroundColor: isOpen ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.4)'
                }}
                transition={{type: 'spring', stiffness: 280, damping: 30}}
            >
                <motion.img
                    key={'bookmarkIcon'}
                    src={arrowIcon}
                    className={styles.bookmarkIcon}
                    initial={{rotate: 0}}
                    animate={{rotate: isOpen ? 0 : 180}}
                    transition={{duration: 0.3}}
                    alt={'arrow'}
                >
                </motion.img>
                <span className={styles.bookmarkText}>{t('info.header')}</span>
            </motion.div>

            <motion.div
                className={styles.container}
                initial="hidden"
                animate={isOpen ? 'visible' : 'hidden'}
                variants={containerVariants}
            >
                <h1 className={styles.header}>{t('roundStart.infoheader1')}</h1>
                <hr className={styles.breakLine}/>
                <p className={styles.paragraph}>{t('roundStart.infoparagraph1')}</p>
                <div className={styles.randomizerContainer}>
                    <Randomizer/>
                </div>

                <h1 className={styles.header}>{t('roundStart.infoheader2')}</h1>
                <hr className={styles.breakLine}/>
                <p className={styles.paragraph}>{t('roundStart.infoparagraph2')}</p>

                <h1 className={styles.header}>{t('roundStart.infoheader3')}</h1>
                <hr className={styles.breakLine}/>
                <p className={styles.paragraph}>{t('roundStart.infoparagraph3')}</p>
            </motion.div>
        </>
    );
};

export default GameInfoTab;
