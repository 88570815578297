"use client";
/* eslint-disable react/jsx-no-constructed-context-values */
import React, {ReactNode, useState} from 'react';
import {useQuery} from 'react-query';
import apiClient from "../utils/apiClient";
import {ERROR} from "../config/constants";

interface TokenData {
    access_token: string;
    refresh_token: string;
}

interface UserData {
    username: string;
    password: string;
    email: string;
    firstName: string;
    lastName: string;
    userId: number;
}

interface UserBalance {
    totalValue: number;
    moneyValue: number;
    bonusValue: number;
    goodsValue: number;
}

interface AppContextProps {
    tokenData: TokenData | null;
    setTokenData: React.Dispatch<React.SetStateAction<TokenData | null>>;
    userData: UserData;
    setUserData: React.Dispatch<React.SetStateAction<UserData | any>>;
    callback: (eventType: string, eventData: any) => void;
    setCallback: React.Dispatch<React.SetStateAction<(eventType: string, eventData: any) => void>>;
    userBalance: UserBalance | null;
    setUserBalance: React.Dispatch<React.SetStateAction<UserBalance | any>>;
    currentModal: string;
    setCurrentModal: React.Dispatch<React.SetStateAction<string>>;
    currentError: string | undefined;
    setCurrentError: React.Dispatch<React.SetStateAction<string | undefined>>;
    userPaymentSuccess: boolean;
    setUserPaymentSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    refetchCustomerData: () => void;
    refetchCustomerBalance: () => void;
    getReplayTicket: (combinationId: string) => Promise<any>;
    appIsMuted: boolean;
    setAppIsMuted: React.Dispatch<React.SetStateAction<boolean>>;
    isReplay: boolean;
    setIsReplay: (val: boolean) => void;
    currencyFormat: string;
    setCurrencyFormat: (val: string) => void;
    currencyLocale: string;
    setCurrencyLocale: (val: string) => void;
}

interface AppProviderProps {
    children: ReactNode;
    value: Partial<AppContextProps>;
}

const initialAppContextProps: AppContextProps = {
    tokenData: null,
    setTokenData: () => {
    },
    userData: {} as UserData,
    setUserData: () => {
    },
    callback: () => {
    },
    setCallback: () => {
    },
    userBalance: null,
    setUserBalance: () => {
    },
    currentError: '',
    setCurrentError: () => {
    },
    currentModal: '',
    setCurrentModal: () => {
    },
    userPaymentSuccess: false,
    setUserPaymentSuccess: () => {
    },
    refetchCustomerData: () => {
    },
    refetchCustomerBalance: () => {
    },
    getReplayTicket: async () => {},
    appIsMuted: false,
    setAppIsMuted: () => {
    },
    isReplay: false,
    setIsReplay: () => {
    },
    currencyFormat: '',
    setCurrencyFormat: () => {
    },
    currencyLocale: '',
    setCurrencyLocale: () => {
    },
};

const AppContext = React.createContext<AppContextProps>(initialAppContextProps);

function AppProvider({children, value}: AppProviderProps) {
    const [tokenData, setTokenData] = useState<TokenData | null>(null);
    const [userData, setUserData] = useState({} as UserData);
    const [callback, setCallback] = useState<(eventType: string, eventData: any) => void>(() => {});
    const [userBalance, setUserBalance] = useState<UserBalance | null>(null);
    const [userPaymentSuccess, setUserPaymentSuccess] = useState(false);
    const [currentModal, setCurrentModal] = useState('');
    const [currentError, setCurrentError] = useState<string | undefined>();
    const [appIsMuted, setAppIsMuted] = useState(false);
    const [isReplay, setIsReplay] = useState(false);
    const [currencyFormat, setCurrencyFormat] = useState('');
    const [currencyLocale, setCurrencyLocale] = useState('');

    const fetchUserBalance = async () =>
        apiClient.get('api/CustomerTransaction/CombinedBalance', {});

    const fetchReplayTicket = async (combinationId: string) =>
        apiClient.get(`/api/Game/GetTicket?combinationId=${combinationId}`, {});

    const balance = useQuery('balance', fetchUserBalance, {
        refetchInterval: 20000,
        enabled: !!tokenData,
        onSuccess: (response: any) => {
            setUserBalance(response);
        },

        onError: (error: any) => {
            setCurrentError(error.data);
            setCurrentModal(ERROR);

        },
        refetchOnWindowFocus: false,
    });

    const refetchCustomerBalance = () => {
        balance.refetch();
    };

    const customer = useQuery('user', () => apiClient.get('api/Customer', {}), {
        enabled: !!tokenData,
        refetchOnWindowFocus: false,
        onSuccess: (response: any) => {
            setUserData(response);
        },
        onError: (error: any) => {
            setCurrentError(error.data);
            setCurrentModal(ERROR);
        },
    });

    const refetchCustomerData = () => {
        customer.refetch();
    };

    const getReplayTicket = async (combinationId: string) => {
        return await fetchReplayTicket(combinationId);
    };

    return (
        <AppContext.Provider
            value={{
                ...value,
                tokenData,
                setTokenData,
                userData,
                setUserData,
                callback,
                setCallback,
                userBalance,
                setUserBalance,
                currentModal,
                setCurrentModal,
                currentError,
                setCurrentError,
                userPaymentSuccess,
                setUserPaymentSuccess,
                refetchCustomerData,
                refetchCustomerBalance,
                getReplayTicket,
                appIsMuted,
                setAppIsMuted,
                isReplay,
                setIsReplay,
                currencyFormat,
                setCurrencyFormat,
                currencyLocale,
                setCurrencyLocale,
            }}
        >
            {children}
        </AppContext.Provider>
    );
}

AppProvider.defaultProps = {
    value: {
        userData: null,
        tokenData: null,
    },
};

export {AppContext, AppProvider};
